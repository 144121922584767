<template>
	<mobile-float-menu>
		<template v-slot:content>
			<div class="container-fluid">
				<div class="row ecart-signup justify-content-center bg-black">
					<div class="col-12 col-md-6 col-lg-5 d-flex flex-column justify-content-center signup-content">
						<Signup class="p-2 p-lg-4 mb-5 animated fadeInDown" mobileVerison />
					</div>
				</div>
			</div>
		</template>
	</mobile-float-menu>
</template>

<script>
	import MobileFloatMenu from "@/components/MobileFloatMenu";
	import Signup from '@/components/Signup';
	export default {
		components: {
			Signup,
			MobileFloatMenu,
		},
		mounted() { // TODO: Remove this line if is necessary
			window.location.href = '/';
		}
	}
</script>

<style lang="scss">
	.ecart-signup {
		min-height: 100vh;

		.signup-content {
			background-color: white;
		}

		@media only screen and (max-width: 767px) {
			.signup-content {
				background-color: transparent;
			}

			background-image: url('../../assets/images/backgrounds/bg-3.png');
			background-size: cover;
		}
	}
</style>